import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Layout, Container, Row, Col } from 'layout';
import Banner from 'components/Banner';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import { useUpdateOTLds } from '@phx-husky/use-update-ot-lds';
import { LdsPageComponentProps } from './models';
import './LdsPageMain.scss';
import './LdsPageMainExtends.scss';
import './LdsPageRTL.scss';

const LdsPage: FC<LdsPageComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    page: {
      nodes: [
        {
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaDescription,
          seoExternalHreflangs,
          defaultCompositions,
          url,
          banner,
          body,
          versionId,
          platform,
          businessId,
        },
      ],
    },
  },
}) => {
  const { updatedLdsContent, isCheckingLdsVersion } = useUpdateOTLds({
    baseUrl: process.env.GATSBY_LDS_BASE_URL as string,
    businessId,
    versionId: versionId ?? '',
    platform: platform ?? '',
  });

  const bodyContent = updatedLdsContent ?? body;

  return (
    <Layout
      {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
      seo={{
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaDescription,
        seoExternalHreflangs,
      }}
      className="lds-page"
    >
      <div className="lds-page__wrapper">
        {banner.map(({ properties: { title, ...restBannerProperties } }) => (
          <Banner
            key={title}
            url={url}
            title={title}
            {...{ ...restBannerProperties, image: restBannerProperties.localImage }}
          />
        ))}
        {!isCheckingLdsVersion && bodyContent ? (
          <div className="lds-content" data-version={versionId}>
            <Container>
              <Row>
                <Col sm="12" md="12" lg="12" xl="12">
                  <DangerouslySetInnerHtml
                    html={bodyContent.replace(new RegExp(/(<p>&nbsp;<\/p>)/gi), '')}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($url: String = "", $lang: String) {
    page: allUmbracoLds(filter: { url: { eq: $url }, lang: { eq: $lang } }) {
      nodes {
        url
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        defaultCompositions {
          ...DefaultCompositionsFragment
        }
        body
        versionId
        businessId
        platform
        banner {
          properties {
            title
            variant
            localImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            imageAlt
            backgroundColour
          }
        }
      }
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default LdsPage;
